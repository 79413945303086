<template>
    <div class="dashboard-modal-wrapper">
        <Modal :title="modalTitle" v-model="isShow">
            <template #modal-title>
                <div class="modal-content-title">
                    <div class="name">
                        {{ title }}
                    </div>
                    <div class="company">{{ subTitle }}</div>
                </div>
            </template>
            <template #modal-content>
                <div class="modal-content-wrap">
                    <div class="modal-content-files">
                        <div class="files-title">
                            <div>檔案名稱</div>
                            <div>附件</div>
                        </div>
                        <template v-if="files?.length > 0 && files[0]?.link">
                            <div
                                v-for="(file, idx) in files"
                                :key="idx"
                                class="files-link"
                            >
                                <div>{{ file.name }}</div>
                                <div
                                    class="download-icon"
                                    @click="
                                        downloadKMFile(file.link, file.name)
                                    "
                                >
                                    <div
                                        v-if="
                                            downloadingFile.includes(file.name)
                                        "
                                        class="process-loading-icon"
                                    />
                                    <img
                                        v-else
                                        src="@/assets/images/icon_blue_download.webp"
                                        alt="download document"
                                    />
                                </div>
                            </div>
                        </template>
                        <div v-else class="modal-nodata">無附件</div>
                    </div>
                    <div class="modal-content-content">
                        <div class="content-title">內文</div>
                        <div v-if="!!content" v-html="content"></div>
                        <div v-else class="modal-nodata">無內文</div>
                    </div>
                </div>
            </template>
            <template #modal-bottom>
                <div class="time">
                    <template v-if="date">
                        {{ `${dateTitle}：${date?.replace(/-/g, '.')}` }}
                    </template>
                </div>
                <Button
                    buttonWord="關閉"
                    buttonStyle="grey"
                    @click="turnOffModal"
                />
            </template>
        </Modal>
    </div>
</template>
<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import { getKMFileAPI } from '@/assets/javascripts/api.js'

export default {
    name: 'DashboardModal',
    emits: ['close', 'update:isShowModalProp'],
    components: { Modal, Button },
    props: {
        isShowModalProp: {
            type: Boolean,
            default: false
        },
        modalTitle: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        files: {
            type: Array,
            default: function () {
                return []
            }
        },
        content: {
            type: String,
            default: ''
        },
        dateTitle: {
            type: String,
            default: '日期'
        },
        date: {
            type: String,
            default: ''
        }
    },
    methods: {
        turnOffModal: function () {
            this.$emit('close')
        },
        async downloadKMFile(url, fileName) {
            this.downloadingFile.push(fileName)
            const link = document.createElement('a')
            try {
                const res = await getKMFileAPI(url)
                const downloadURL = window.URL.createObjectURL(
                    new Blob([res.data])
                )
                link.download = fileName
                link.href = downloadURL
                document.body.append(link)
                link.click()
                link.remove()
                this.$setGaEvent(`downloadKMFile`, 'click-icon')
            } catch (e) {
                this.$showErrorMessage(
                    '下載檔案時發生錯誤',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            } finally {
                setTimeout(() => URL.revokeObjectURL(link.href), 1000)
                this.downloadingFile = this.downloadingFile.filter(
                    (file) => file !== fileName
                )
            }
        }
    },
    watch: {
        isShowModalProp() {
            this.isShow = this.isShowModalProp
        },
        isShow() {
            this.$emit('update:isShowModalProp', this.isShow)
        }
    },
    data() {
        return {
            isShow: this.isShowModalProp,
            downloadingFile: []
        }
    }
}
</script>

<style lang="scss" scoped>
@mixin scrollBar() {
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: $sum-row-grey;
        border-radius: 15px;
    }
}
.dashboard-modal-wrapper {
    :deep(.modal) {
        .title {
            :first-child {
                display: flex;
                align-items: center;
                gap: 10px;
                &::before {
                    content: '';
                    background-image: url('~@/assets/images/dashboard/icon_downloadcontent@3x.webp');
                    background-size: 20px 20px;
                    height: 20px;
                    width: 20px;
                }
            }
        }

        .modal-content-title {
            margin-top: 17px;
            color: $sixth-black;
            .name {
                font-size: 1rem;
                font-weight: 700;
            }

            .company {
                font-size: 15px;
            }
        }

        .modal-content {
            .modal-content-wrap {
                font-size: 14px;
                color: $sixth-black;
                height: 328px;
                padding-right: 10px;
                margin-right: -15px;
                overflow: auto;
                @include scrollBar();
                .modal-content-files {
                    margin-bottom: 17px;
                    .files-title,
                    .files-link {
                        display: grid;
                        grid-template-columns: 1fr 52px;
                        height: 40px;
                        place-items: center start;
                        :last-child {
                            place-self: center;
                        }
                    }

                    .files-link {
                        .download-icon {
                            width: 20px;
                            height: 20px;
                            img {
                                height: 20px;
                                cursor: pointer;
                            }
                        }

                        .process-loading-icon {
                            height: 12px;
                            width: 12px;
                        }
                    }
                }
                .modal-content-files .files-title,
                .modal-content-content {
                    word-break: break-all;
                    p {
                        margin: 5px 0 0;
                    }

                    a {
                        color: $fourth-blue;
                    }

                    .content-title {
                        font-weight: 500;
                        height: 40px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        .modal-bottom {
            justify-content: space-between;
            .time {
                font-size: 12px;
                color: $placeholder-black;
            }
        }

        .modal-nodata {
            display: grid;
            place-items: center;
            margin: 10px 0;
            color: $sixth-black-light;
        }
    }
}
</style>
